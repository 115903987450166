import makeRequest, { makeParams } from "api/makeRequest";
import { RequstMethods } from "api/requestMethode";
import url from "api/urls";
import { useParams } from "react-router-dom";

export class AdminService {
    static async getAllUnmappedUser(examId: any, search: string, pageSize: number, pageNumber: number) {
        const params = makeParams([
            {
                index: "search",
                value: search
            },
            {
                index: "pageSize",
                value: pageSize
            },
            {
                index: "pageNumber",
                value: pageNumber
            },
        ])
        return await makeRequest(url.admin.getAllUnmappedUsers + "/" + examId + params, RequstMethods.GET)
    }

    static async createMappedUser(examId: any, userId: any) {
        return await makeRequest(url.admin.createMappedUser + "/" + examId + "/" + userId, RequstMethods.POST)
    }

    static async mapMultipleusers(examId: any, payload: any) {
        return await makeRequest(url.admin.mapMultipleUsers + "/" + examId, RequstMethods.POST, payload)
    }

    static async toggleExam(examId: any, payload: any) {
        return await makeRequest(url.admin.toggleUpdate + "/" + examId, RequstMethods.PUT, payload)
    }

    static async getAllStatsData(fromDate: any, toDate: any) {
        const params = makeParams([
            {
                index: "fromDate",
                value: fromDate
            },
            {
                index: "toDate",
                value: toDate
            },
        ])
        return await makeRequest(url.stats + params, RequstMethods.GET)
    }
}