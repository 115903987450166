import makeRequest from "../api/makeRequest";
import url from "../api/urls";
import { RequstMethods } from "../api/requestMethode";


export class UserServices {
    static async getAllUser() {
        return await makeRequest(url.user.getAllUsers , RequstMethods.GET)
    }

    static async resetPasswordForUser(payload:any) {
        return await makeRequest(url.user.resetPasswordForUser , RequstMethods.PUT , payload)
    }

    static async deleteStudentMapping(id:string ) {
        return await makeRequest(url.user.deleteStudentMapping+"/"+id, RequstMethods.DELETE)
    }
    
    static async disableStudentMapping(id:string ) {
        return await makeRequest(url.user.disableStudentMapping+"/"+id, RequstMethods.PUT)
    }

    static async toggleStudentMapping(id:string){
        return await makeRequest(url.user.toggleStudentMapping+'/'+id , RequstMethods.PUT);
    }

    static async unmarkCompleted(mappingId: string){
        return await makeRequest(url.user.unMarkCompletedMapping+"/"+mappingId, RequstMethods.PUT);
    }

    static async genrateCertificate(examMappingId: string) {
        return await makeRequest(url.user.genrateCertificate + "/" + examMappingId, RequstMethods.GET)
    }

    static async toggleStatusMultiple(payload: any) {
        return await makeRequest(url.user.toggleMultipleStudentMapping, RequstMethods.PUT, payload)
    }
}