import moment from 'moment';
import { Form, Table } from 'react-bootstrap';

interface IUsersTable {
  tableData: any;
  tableRows?: any;
  pageNumber?: number;
  pageCount?: number;
  showCheckbox?: boolean;
  selectedIds?: any;
  setSelectedIds?: any;
}

const UsersTable = ({
  tableData,
  tableRows,
  pageNumber,
  pageCount,
  showCheckbox = false,
  selectedIds,
  setSelectedIds,
}: IUsersTable) => {
  const formatDate = (date: any) => {
    return moment(date).format('DD-MMM-YYYY HH:mm');
  };

  function handleSelectAll(e: any) {
    if (e.target.checked) {
      setSelectedIds(tableData?.map((item: any) => item?._id));
    } else {
      setSelectedIds([]);
    }
  }

  return (
    <Table striped borderless className="xrg-table" size="lg" responsive>
      <thead>
        <tr>
          {
            showCheckbox &&
            <th>
              <Form.Check type="checkbox" onChange={handleSelectAll} checked={selectedIds?.length === tableData?.length} />
            </th>
          }
          <th>Sr. No.</th>
          <th className='text-nowrap'>Username/Reg. No.</th>
          {/* <th className='w-auto'>DOB</th> */}
          <th className='text-center'>Invigilator</th>
          <th className='text-center'>Student Submission</th>
          <th className='text-center'>Invigilator Submission</th>
          <th className='text-center'>Completed</th>
          <th className='text-center'>Mapping Status</th>
          <th> Attempted / Approved / Attempted but Unapproved / Review</th>
          <th>Certificate</th>
          <th className='text-nowrap'>Lock/Un-Lock</th>
        </tr>
      </thead>
      <tbody>{tableRows && tableRows}</tbody>
    </Table>
  );
};

export default UsersTable;
