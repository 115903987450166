import { InvigilatorService } from "Services/invigilatorService";
import AllottedExam from "components/AllotedExams";
import { useEffect, useState } from "react";
import { Badge, Button, Card, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import InvigilatorAccessModal from './../../../components/Modals/ManageInvigilatorAccess.modal';
import { ExamServices } from "Services/examServices";
import EditAccessModal from "components/Modals/EditAccessModal";


export default function InvigilatorDetails() {

    const [id, setId] = useState<any>('');
    const [invigilator, setInvigilator] = useState<any>();
    const [exams, setExams] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [editExam, setEditExam] = useState<any>(null);
    const params = useParams();

    async function getDetails() {
        if (params.invigilatorId && params.invigilatorId !== "") {
            setLoading(true);
            InvigilatorService.getInvigilator(params.invigilatorId)
                .then((res) => {
                    if (res.status === 200) {
                        setInvigilator(res.data.data?.invigilator);
                    }
                })
                .catch(e => {
                    console.log(e);
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    }

    async function getAllExams() {
        await ExamServices.getAllExamsLean()
            .then((res) => {
                if (res.status === 200) {
                    res.data.data?.examData &&
                        res.data.data.examData.length > 0 &&
                        setExams(res.data.data.examData.map((item: any) => {
                            return {
                                value: item._id,
                                label: item.examName
                            }
                        }))
                }
            })
            .catch(e => {
                console.log(e);
            })
    }

    useEffect(() => {
        getDetails();
        getAllExams();
    }, []);


    return (
        <>
            {
                loading ?
                    <div className="text-center p-3 mt-5">
                        <span className="fs-4">
                            Loading... <Spinner animation="border" role="status" />
                        </span>
                    </div> :

                    <div className="mt-4">
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>Invigilator Details</h5>
                            <Button size="sm" variant="secondary" onClick={() => setId(params.invigilatorId)}>Allot New Exam</Button>
                        </div>
                        <Card className="mt-3">
                            <Card.Body>
                                <span>Name: <span className="fw-bold">{invigilator?.name}</span></span> <br />
                                <span>E-mail: <span className="fw-bold">{invigilator?.email}</span></span> <br />
                                <span>Mobile: <span className="fw-bold">{invigilator?.mobile}</span></span> <br />
                                {
                                    invigilator?.status === 'active' ?
                                        <Badge pill bg='success'>Active</Badge> :
                                        <Badge pill bg='secondary'>In-active</Badge>
                                }
                                {
                                    invigilator?.isSuspended &&
                                    <Badge pill bg='danger'>Suspended</Badge>
                                }
                            </Card.Body>
                        </Card>

                        <h5 className="mt-3">Alloted Exams</h5>
                        {
                            invigilator?.allottedExams?.length > 0 ?
                                invigilator?.allottedExams?.map((exam: any, index: number) => {
                                    return (
                                        <div key={exam?._id}>
                                            <AllottedExam allottedExam={exam} reload={getDetails} exam={exams?.find((item: any) => { return item.value === exam?.examId })} onEdit={() => setEditExam(exam)} />
                                        </div>
                                    )
                                })
                                :
                                <div className="text-center card mt-5 p-3">
                                    No exams alloted
                                </div>
                        }
                    </div>
            }
            <InvigilatorAccessModal
                id={id}
                setId={setId}
                reload={getDetails}
                exams={exams}
            />

            <EditAccessModal
                show={(editExam && editExam?.examId !== "")}
                setShow={() => setEditExam(null)}
                data={editExam}
                reload={getDetails}
                exams={exams}
            />
        </>
    )
}