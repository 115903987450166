import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import TableFooter from '../../../../components/Tables/Footer.table';
import HeaderTable from '../../../../components/Tables/Header.table';
import UsersTable from '../../../../components/Tables/UsersTable';
import { ExamServices } from '../../../../Services/examServices';
import TableRows from './TableRows';

import { CSVDownload, CSVLink } from 'react-csv';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import FilterModal from 'components/Modals/Filter.modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import PromptModal from 'components/Modals/Prompt.modal';
import { UserServices } from 'Services/userServices';
interface IUsersTable {
  pageCount: any;
  setPageCount: any;
  userData: any;
  pageNumber: any;
  setPageNumber: any;
  totalPages: any;
  reloadData: any;
  filterData: any
  setFilterData: any
}
export default function ExamUserTable({
  pageCount,
  setPageCount,
  pageNumber,
  setPageNumber,
  userData,
  totalPages,
  reloadData,
  filterData,
  setFilterData
}: IUsersTable) {
  const params: any = useParams();

  const [showFilterModal, setShowFilterModal] = useState<boolean>(false)
  const [downloadData, setDownloadData] = useState<any>([]);
  const [selectedStudentIds, setSelectedStudentIds] = useState<any[]>([]);
  const [confirmPrompt, setConfirmPrompt] = useState<boolean>(false);

  const onDownloadClick = async () => {
    await ExamServices.getAllMappedUser(params.examId)
      .then((res) => {
        if (res.status === 200) {
          setDownloadData(
            res.data.data.users.map((data: any) => {
              return {
                'Mapping Id': data._id,
                'Registration Number': data?.student?.registrationNumber,
                Email: data.student?.email || 'N/A',
                'DOB': moment(data.student && data.student.dob).format('DD-MM-YYYY'),
                'Membership ID': data.student?.membershipId || 'N/A',
                'Invigilator': data.student && data.invigilators?.map((invigilator: any) => invigilator?.invigilatorEmail).join(', '),
                'Student Submission': data.studentSubmitForReview ? 'Yes' : 'No',
                'Invigilator Submission': data.invigilatorSubmitForReview ? 'Yes' : 'No',
                'Completed': data.completionStatus ? 'Yes' : 'No',
                'Certificate Status': data?.certificateStatus,
                'Attempted': data.attemptedCount,
                "Approved": data.approvedCount,
                "Attempted But Unapproved Count": data.attemptedButUnapprovedCount,
                "In Review": data.inReviewCount,
              };
            }),
          );
        }
      })
      .catch((err) => {
        toast.error('Unable to fetch User data');
      });
  };

  async function toggleMultipleUserStatus() {
    if (!selectedStudentIds?.length) {
      toast.error("Please select at least one student")
      return;
    }

    await UserServices.toggleStatusMultiple({ mappingId: selectedStudentIds })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Success");
          setSelectedStudentIds([]);
          reloadData();
          setConfirmPrompt(false);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message || err?.response?.data || "Something went wrong")
      })
  }

  useEffect(() => {
    onDownloadClick();
  }, []);

  return (
    <>
      <div className="d-flex flex-column mt-2">
        <div className="d-flex justify-content-end mb-2">
          <div className="d-flex  gap-2 float-end">
            <div>
              <CSVLink data={downloadData || []}><span className='btn btn-primary'>Export as CSV</span></CSVLink>
            </div>
            <div>
              <Button variant='outline-secondary' onClick={() => setShowFilterModal(true)}>
                <FontAwesomeIcon icon={faFilter} />
              </Button>
            </div>
            {
              selectedStudentIds?.length > 0 &&
              <>
                <Button size='sm' onClick={() => setConfirmPrompt(true)}>
                  Toggle Mapping Status of {selectedStudentIds?.length} Students
                </Button>
                <Button size='sm' variant='warning' onClick={() => setSelectedStudentIds([])}>
                  Deselect All
                </Button>
              </>
            }
          </div>
        </div>

        <UsersTable
          tableData={userData}
          tableRows={<TableRows data={userData} reloadData={reloadData} showCheckbox={true} selectedIds={selectedStudentIds} setSelectedIds={setSelectedStudentIds} />}
          pageNumber={pageNumber}
          pageCount={pageCount}
          showCheckbox={true}
          selectedIds={selectedStudentIds}
          setSelectedIds={setSelectedStudentIds}
        />
      </div>
      {/* <TableFooter
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        totalPages={totalPages}
      /> */}

      <FilterModal
        show={showFilterModal}
        handleClose={() => setShowFilterModal(false)}
        reload={reloadData}
        filterData={filterData}
        setFilterData={setFilterData}
      />

      <PromptModal
        setShow={setConfirmPrompt}
        show={confirmPrompt}
        body={
          <>
            <div>
              <p>Are you sure you want to toggle status of All Selected candidates?</p>
            </div>
          </>
        }
        submitButton={
          <Button
            variant="warning"
            className="ms-2"
            onClick={toggleMultipleUserStatus}
          >
            Submit
          </Button>
        }
      />
    </>
  );
}
