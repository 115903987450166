import { ExamServices } from 'Services/examServices';
import React, { useEffect, useState } from 'react';
import { ListGroup, Container, Row, Col, Accordion, Card, Button } from 'react-bootstrap';
import EditAccessModal from './Modals/EditAccessModal';
import { InvigilatorService } from 'Services/invigilatorService';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

interface Exam {
    students?: string[];
    _id: string;
    examId: string;
}

interface AllottedExamsProps {
    allottedExam: Exam;
    reload: any;
    exam: any;
    onEdit: any;
}

const AllottedExam: React.FC<AllottedExamsProps> = ({ allottedExam, reload, exam, onEdit }) => {

    const [examStudents, setExamStudents] = useState<any>([]);
    const { invigilatorId } = useParams();
    const [loading, setLoading] = useState<boolean>(false);

    // async function getAllExams() {
    //     await ExamServices.getAllExamsLean()
    //         .then((res) => {
    //             if (res.status === 200) {
    //                 let filteredExam = res.data.data?.examData?.filter((exam: any) => { return exam._id === allottedExam.examId })
    //                 setExam(filteredExam[0]);
    //                 getExamStudents(filteredExam[0]?._id);
    //             }
    //         })
    //         .catch(e => {
    //             console.log(e);
    //         })
    // }

    async function getExamStudents(examId: string) {
        setLoading(true);
        await ExamServices.getAllMappedUserLean(examId)
            .then((res) => {
                if (res.status === 200) {
                    let filteredStudents = [];
                    if (res.data.data.users.length > 0) {
                        filteredStudents = res.data.data.users.filter((item: any) => { return allottedExam.students?.includes(item?.student._id) });
                        // console.log(filteredStudents);
                    }
                    setExamStudents(filteredStudents);
                }
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    async function handleDelete() {
        await InvigilatorService.deleteAllotedExam(allottedExam.examId, invigilatorId)
            .then((res) => {
                if (res.status === 200) {
                    toast.success(res.data?.message);
                    reload();
                }
            })
            .catch(e => {
                console.log(e);
                toast.error(e.response.data.message);
            })
    }

    useEffect(() => {
        if (allottedExam.examId !== '') {
            getExamStudents(allottedExam.examId);
        }
    }, [allottedExam.examId]);


    return (
        <div className='mb-3'>
            <Card>
                <Card.Body>
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <span>
                            <strong>Exam:</strong> {exam?.label}
                        </span>
                        <div className="d-flex gap-2">
                            <Button size="sm" variant="outline-danger" onClick={onEdit}>Edit</Button>
                            <Button size="sm" variant="danger" onClick={() => handleDelete()}>Delete</Button>
                        </div>
                    </div>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Students</Accordion.Header>
                            <Accordion.Body>
                                <Container>
                                    {
                                        loading ? <Row><Col><span className="text-muted">Loading...</span></Col></Row> :
                                            examStudents?.length > 0 ?
                                                examStudents?.map((student: any, index: number) => (
                                                    <Row key={student?.student._id}>
                                                        <span>{index + 1}.{student?.student?.name + ' ' + student.student?.last_name + ' (' + student.student?.username + ' )'}</span>
                                                    </Row>
                                                )) :
                                                <Row>No students allotted</Row>
                                    }
                                </Container>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Card.Body>
            </Card>
        </div>
    );
};

export default AllottedExam;
