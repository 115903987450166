import { AdminService } from 'Services/adminService';
import PromptModal from 'components/Modals/Prompt.modal';
import moment from 'moment';
import { useState } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface IUnmappedUsers {
  tableData: any,
  reload: any,
  startIndex: any
}

const UnmappedUsersTable = ({
  tableData,
  reload,
  startIndex
}: IUnmappedUsers) => {

  const params = useParams();
  const [selectedStudentIds, setSelectedStudentIds] = useState<any[]>([]);
  const [confirmPrompt, setConfirmPrompt] = useState<boolean>(false);

  const createMappeduser = async (userId: any) => {
    await AdminService.createMappedUser(params.examId, userId).then((res) => {
      if (res.status === 200) {
        toast.success("User Mapped Successfully");
        reload();
      }
    }).catch(err => {
      toast.error(err.response.data)
    })
  }

  function handleSelectAll(e: any) {
    if (e.target.checked) {
      setSelectedStudentIds(tableData.map((data: any) => data._id));
    } else {
      setSelectedStudentIds([]);
    }
  }

  function handleSelect(e: any, id: any) {
    if (e.target.checked) {
      setSelectedStudentIds([...selectedStudentIds, id]);
    } else {
      setSelectedStudentIds(selectedStudentIds.filter((studentId: any) => studentId !== id));
    }
  }

  async function mapMultipleUser() {
    if (selectedStudentIds?.length < 0) {
      toast.error("Please select at least one student")
      return;
    }

    await AdminService.mapMultipleusers(params.examId, { students: selectedStudentIds })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data?.newlyMapped + " candidates mapped Successfully, " + res.data?.skipped + " already mapped candidates skipped");
          setSelectedStudentIds([]);
          reload();
          setConfirmPrompt(false);
        }
      })
      .catch(err => {
        toast.error(err.response?.data?.message || err.response?.data || "Something went wrong");
      })
  }


  return (
    <>
      {
        selectedStudentIds?.length > 0 &&
        <div className='d-flex justify-content-end gap-2'>
          <Button size='sm' onClick={() => setConfirmPrompt(true)}>
            Map Selected {selectedStudentIds?.length} Students
          </Button>
          <Button size='sm' variant='warning' onClick={() => setSelectedStudentIds([])}>
            Deselect All
          </Button>
        </div>
      }
      <Table striped borderless className="xrg-table">
        <thead>
          <tr>
            <th>
              <Form.Check type="checkbox" onChange={handleSelectAll} checked={selectedStudentIds?.length === tableData?.length} />
            </th>
            <th>Sr. No.</th>
            <th>Name</th>
            <th>Email</th>
            <th>DOB</th>
            <th>Reg. No.</th>
            <th>Gender</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tableData && tableData.length > 0 ? tableData.map((data: any, index: number) => {
            return (
              <tr>
                <td><Form.Check type="checkbox" onChange={(e) => handleSelect(e, data?._id)} checked={selectedStudentIds.includes(data?._id)} /></td>
                <td>{startIndex + index + 1}</td>
                <td className="xrg-text-12">{data?.name}{" "}{data?.last_name}</td>
                <td className="xrg-text-12">{data?.email}</td>
                <td className="xrg-text-10">{moment(data?.dob).format("DD MMM YYYY")}</td>
                <td className="xrg-text-12">{data?.registrationNumber}</td>
                <td className="xrg-text-12">{data?.gender?.toUpperCase()}</td>
                {!data?.isMapped ?
                  <td><Button size="sm" variant='success' onClick={() => createMappeduser(data._id)}>Map this User</Button></td> :
                  <td><span className="text-primary fw-normal">Already Mapped</span></td>
                }
              </tr>
            )
          }) : "No users found"}
        </tbody>
      </Table>

      <PromptModal
        setShow={setConfirmPrompt}
        show={confirmPrompt}
        body={
          <>
            <div>
              <p>Are you sure you want to Map All Selected candidates?</p>
              <p>Once mapped this action cannot be reversed</p>
            </div>
          </>
        }
        submitButton={
          <Button
            variant="warning"
            className="ms-2"
            onClick={mapMultipleUser}
          >
            Map {selectedStudentIds?.length} Candidates
          </Button>
        }
      />
    </>
  );
};

export default UnmappedUsersTable;
