import { InvigilatorService } from "Services/invigilatorService";
import { useEffect, useState } from "react";
import { Badge, Button, Form, Offcanvas } from "react-bootstrap"
import { toast } from "react-toastify";
import Select from "react-select";
import { ExamServices } from "Services/examServices";
import { useParams } from "react-router-dom";

interface IAddInvigilator {
    show: boolean;
    setShow: any;
    data: any;
    reload: any;
    exams: any[]
}

export default function EditAccessModal({ show, setShow, data, reload, exams }: IAddInvigilator) {

    const params = useParams();
    const [examStudents, setExamStudents] = useState([]);
    const [selectedExam, setSelectedExam] = useState<any>(undefined);
    const [selectedStudents, setSelectedStudents] = useState<any>([]);

    async function getExamStudents() {
        await ExamServices.getAllMappedUserLean(data?.examId)
            .then((res) => {
                if (res.status === 200) {
                    res.data.data?.users &&
                        res.data.data.users.length > 0 &&
                        setExamStudents(res.data.data.users.map((item: any) => {
                            return {
                                value: item.student?._id,
                                label: item.student?.name + ' ' + item.student?.last_name + ' (' + item.student?.username + ' )'
                            }
                        }))
                }
            })
            .catch(e => {
                console.log(e);
            })
    }

    async function handleSave() {
        if (!selectedExam || !selectedExam.value) {
            toast.error('Please select exam')
            return;
        }
        if (!selectedStudents || selectedStudents.length === 0) {
            toast.error('Please select students')
            return;
        }

        let payload = {
            examId: selectedExam.value,
            students: selectedStudents.map((item: any) => item.value),
            _id: data._id
        }

        await InvigilatorService.updateAllotedExam(params.invigilatorId, payload)
            .then((res) => {
                if (res.status === 200) {
                    console.log(res.data);
                    if (res.data?.alreadyAssigned?.length > 0) {
                        toast.warning(
                            <>
                                <span className="text-bold">
                                    Following students are already assigned to other invigilator
                                </span>
                                {
                                    res.data?.alreadyAssigned?.map((item: any) =>
                                        <Badge className="mx-1" key={item._id} pill>{item?.name}</Badge>
                                    )
                                }
                            </>
                        )
                    } else {
                        toast.success('Access updated successfully');
                    }
                    reload();
                    onModalClose();
                }
            })
            .catch(e => {
                console.log(e);
                toast.error(e.response?.data?.message || e.response?.data);
            })
    }

    function onModalClose() {
        setSelectedExam(undefined);
        setSelectedStudents([]);
        setExamStudents([]);
        setShow(false);
    }

    useEffect(() => {
        if (show) {
            getExamStudents();
        }
    }, [show]);

    useEffect(() => {
        if (show) {
            if (exams.length > 0) {
                let filtered = exams.filter((item: any) => {
                    return item?.value === data?.examId;
                })
                setSelectedExam(filtered[0]);
            }

            if (examStudents.length > 0) {
                let filtered = examStudents.filter((item: any) => {
                    return data?.students.includes(item.value);
                })
                setSelectedStudents(filtered);
            }
        }
    }, [exams, examStudents])



    return (
        <>
            <Offcanvas show={show} onHide={onModalClose} placement="end" animation={true}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Update Access</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="d-flex flex-column justify-content-between h-100">
                        <div className="">
                            <Form.Group className="mb-3">
                                <Form.Label>Exams</Form.Label>
                                <Select
                                    options={exams}
                                    onChange={e => setSelectedExam(e)}
                                    value={selectedExam}
                                    isClearable
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Students</Form.Label>
                                <Select
                                    options={examStudents}
                                    value={selectedStudents}
                                    onChange={e => setSelectedStudents(e)}
                                    isMulti
                                />
                                {
                                    examStudents.length > 0 &&
                                    <div className="text-end mt-1" style={{ cursor: 'pointer' }}>
                                        <Badge pill bg="secondary" onClick={() => setSelectedStudents(examStudents)} >Select All</Badge>
                                    </div>
                                }
                            </Form.Group>
                        </div>
                        <Button variant="primary" className="w-100" onClick={handleSave}>Save</Button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}